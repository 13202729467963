<template>
    <v-dialog v-model="show" max-width="400" :persistent="loading">
        <template #activator="props">
            <slot name="activator" v-bind="props"></slot>
        </template>
        <v-card>
            <v-form v-model="valid" @submit.stop.prevent="onSubmit">
                <vlab-base-card-title :title="$t('content.form')" :dismissible="!loading" @close="show = false" />
                <v-card-text>
                    <v-text-field v-model="nxSaleId" label="ID de vente NX" outlined :rules="[$required]" />
                    <p v-if="done">Import effectué</p>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn type="submit" block rounded color="primary" :disabled="!valid || loading" :loading="loading">OK</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'

export default {
    name: 'hdv-old-site-sync',

    components: { VlabBaseCardTitle },

    data: () => ({
        show: false,
        valid: false,
        loading: false,
        done: false,
        nxSaleId: null
    }),

    methods: {
        onSubmit() {
            this.loading = true
            this.done = false
            return this.$api.post(`/nx/website/sales/${this.nxSaleId}`)
                .then(() => {
                    this.done = true
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
