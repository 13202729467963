<template>
    <v-card class="pa-0 ma-0" flat>
        <vlab-base-card-title :title="$t('salevacations.title')" :dismissible="false" hideSpacer>
            <template #buttons>
                <v-spacer />
                <hdv-sale-vacation-list-import-btn
                    :label="$t('salevacations.btnWeb')"
                    icon="$vuetify.icons.importSale"
                    dark
                    small
                    icon-btn
                    @click:import="importSale"
                />
            </template>
        </vlab-base-card-title>

        <div class="d-flex flex-wrap">
            <v-hover
                v-for="vacation in vacations"
                :key="vacation.id"
                v-slot="{ hover }">
                <hdv-sale-vacation-list-item
                    :sale-id="saleId"
                    :selected="hover"
                    :vacation="vacation"
                    :counts="counts[vacation.id]"
                    :hover="hover"
                    @click:import="ev => $emit('click:import', ev)" />
            </v-hover>
        </div>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvSaleVacationListItem from '@/components/sale-vacation-list/HdvSaleVacationListItem'
import HdvSaleVacationListImportBtn from '@/components/sale-vacation-list/HdvSaleVacationListImportBtn.vue'

export default {
    name: 'hdv-sale-vacation-list',
    components: { VlabBaseCardTitle, HdvSaleVacationListItem, HdvSaleVacationListImportBtn },
    props: {
        saleId: { type: String }
    },
    data: () => ({
        loading: false,
        vacations: [],
        counts: {}
    }),
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.loading = true
            return this.$store.dispatch('sale/get', { id: this.saleId, include_vacations: true })
                .then(sale => {
                    this.vacations = sale.vacations

                    this.counts = sale.vacations.reduce((acc, v) => {
                        acc[v.id] = { web: 0, nx: 0, am: 0 }
                        return acc
                    }, {})

                    return Promise.all([
                        this.$store.dispatch('lot/countByVacation', { saleId: this.saleId }).then(counts => {
                            counts.forEach(count => {
                                if (this.counts[count.vacation_id]) {
                                    this.counts[count.vacation_id].web = count.count
                                    this.counts[count.vacation_id].lot_from = count.lot_from
                                    this.counts[count.vacation_id].lot_to = count.lot_to
                                }
                            })
                        }),
                        this.$store.dispatch('lot/countByVacationAm', { saleId: this.saleId }).then(counts => {
                            counts.forEach(count => {
                                if (this.counts[count.vacation_id]) {
                                    this.counts[count.vacation_id].am = count.count
                                }
                            })
                        }),
                        this.$store.dispatch('lot/countByVacationNx', { nxSaleId: sale.nx_id }).then(counts => {
                            counts.forEach(count => {
                                const vacation = this.vacations.find(v => v.nx_id === count.vacation_id)
                                if (this.counts[vacation?.id]) {
                                    this.counts[vacation.id].nx = count.count
                                }
                            })
                        })
                    ])
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        importSale(ev) {
            this.$emit('click:import-sale', {
                to: 'web',
                withImages: ev.withImages,
                total: Object.values(this.counts).reduce((sum, count) => sum + count.nx, 0)
            })
        }
    }
}
</script>
